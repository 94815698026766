import React from "react";
import './pricing.css';
import { Reveal } from "../common/Reveal";
import FeatureRow from "./FeatureRow";
import Calculator from "./Calculator";


function Pricing01() {
  const [visibleFirst, setVisibleFirst] = React.useState("hide");
  const [visibleSecond, setVisibleSecond] = React.useState("hide");
  const hidden1 = {
    opacity: 0,
    y: 75
  }
  const visible1 = {
    opacity: 1,
    y: 0
  }


  const visible2 = {
    left: "100%"
  }
  return (
    <div className="pricing_01">
      <div className="container-fluid">
        <div className="row">
          <div className="pricing_01__content">
            <div className="pricing_01__content__top">
              <div className="pricing_01__content__description">
                PRICING
              </div>
              <div className="pricing_01__content__title">
                The Perfect Plan For Your Needs
              </div>
            </div>
            <div className="pricing_01__content__bottom">
              <Reveal width="100%" visible1={visible1} visible2={visible2} hidden1={hidden1} hidden2={visible2}>
                <div className="pricing_01__content__container">
                  <table className="pricing_01__content__bottom__table table-1">
                    <tr>
                      <th></th>
                      <th className="pricing_01__content__bottom__card">
                        <p className="pricing_01__content__bottom__card__title">Tier 1</p>
                      </th>
                      <th className="pricing_01__content__bottom__card">
                        <p className="pricing_01__content__bottom__card__title">Tier 2</p>

                      </th>
                      <th className="pricing_01__content__bottom__card">
                        <p className="pricing_01__content__bottom__card__title">Tier 3</p>
                      </th>
                      <th className="pricing_01__content__bottom__card">
                        <p className="pricing_01__content__bottom__card__title">Custom Solutions</p>
                      </th>
                    </tr>
                    <FeatureRow title="Monthly Cost" firstCheck="" first="$495" secondCheck="" second="$1,995" thirdCheck="" third="$4,995" fourthCheck="" fourth=" Have Larger Volume Minutes?" />
                    <FeatureRow title="Minutes Included" firstCheck="" first="1,500" secondCheck="" second="7,000" thirdCheck="" third="20,000" fourthCheck="" fourth=" Can You Build Me An AI Solution I Own?" />
                    <FeatureRow title="Additional Usage per 15 secs" firstCheck="" first="$0.07" secondCheck="" second="$0.06" thirdCheck="" third="$0.05" fourthCheck="" fourth=" Want More Customization?" />
                    <tr>
                      <td className="pricing_01__content__bottom__card"></td>
                      <td className="pricing_01__content__bottom__card">
                        <a href="https://checkout.allriseai.com/tier-1" data-rewardful target="_blank" rel="noreferrer" className="pricing_01__content__bottom__card__button">Sign Up</a>
                      </td>
                      <td className="pricing_01__content__bottom__card">
                        <a href="https://checkout.allriseai.com/tier-2" data-rewardful target="_blank" rel="noreferrer" className="pricing_01__content__bottom__card__button">Sign Up</a>
                      </td>
                      <td className="pricing_01__content__bottom__card">
                        <a href="https://checkout.allriseai.com/tier-3" data-rewardful target="_blank" rel="noreferrer" className="pricing_01__content__bottom__card__button">Sign Up</a>
                      </td>
                      <td className="pricing_01__content__bottom__card">
                        <b>
                          Multiple Products Or Services?
                        </b>
                      </td>
                    </tr>
                    <tr>
                      <td className="pricing_01__content__bottom__card"></td>
                      <td className="pricing_01__content__bottom__card"></td>
                      <td className="pricing_01__content__bottom__card"></td>
                      <td className="pricing_01__content__bottom__card"></td>
                      <td className="pricing_01__content__bottom__card">
                        <a href="https://allriseai.com/contact" data-rewardful target="_blank" rel="noreferrer" className="pricing_01__content__bottom__card__button">Contact Us</a>
                      </td>

                    </tr>
                  </table>
                  <Calculator />
                  <table className="pricing_01__content__bottom__table table-2">
                    <tr onClick={() => setVisibleFirst(visibleFirst === "show" ? "hide" : "show")}>
                      <th className="pricing_01__content__bottom__card">
                        <p className="pricing_01__content__bottom__card__title">Included with Every Tier</p>
                      </th>
                      <th className="pricing_01__content__bottom__card">
                      </th>
                      <th className="pricing_01__content__bottom__card"></th>
                      <th className="pricing_01__content__bottom__card"></th>
                      <th className="pricing_01__content__bottom__card"><p className="pricing_01__content__bottom__card__title"><small>(Click to <span>{visibleFirst === "show" ? "hide" : "view"}</span>)</small></p></th>
                    </tr>
                  </table>

                  <table className="pricing_01__content__bottom__table table-2">

                    {visibleFirst === "show" ?
                      <tr>
                        <th></th>
                        <th className="pricing_01__content__bottom__card">
                          <p className="pricing_01__content__bottom__card__title">Tier 1</p>
                        </th>
                        <th className="pricing_01__content__bottom__card">
                          <p className="pricing_01__content__bottom__card__title">Tier 2</p>

                        </th>
                        <th className="pricing_01__content__bottom__card">
                          <p className="pricing_01__content__bottom__card__title">Tier 3</p>
                        </th>
                        <th className="pricing_01__content__bottom__card">
                          <p className="pricing_01__content__bottom__card__title">Custom Solutions</p>
                        </th>
                      </tr>
                      : ""}
                    {visibleFirst === "show" ?
                      <FeatureRow title="Unlimited Volume" firstCheck="check" secondCheck="check" thirdCheck="check" /> : ""}
                    {visibleFirst === "show" ?
                      <FeatureRow title="Daily Delivered Reporting" firstCheck="check" secondCheck="check" thirdCheck="check" /> : ""}
                    {visibleFirst === "show" ?
                      <tr>
                        <td className="pricing_01__content__bottom__card">
                          <p className="pricing_01__content__bottom__card__title">No Minute Cap</p>
                        </td>
                        <td className="pricing_01__content__bottom__card">
                          <p className="pricing_01__content__bottom__card__title bg-check">
                            <span><i className="fa fa-check"></i></span>
                          </p>
                        </td>
                        <td className="pricing_01__content__bottom__card">
                          <p className="pricing_01__content__bottom__card__title bg-check">
                            <span><i className="fa fa-check"></i></span>
                          </p>
                        </td>
                        <td className="pricing_01__content__bottom__card">
                          <p className="pricing_01__content__bottom__card__title bg-check">
                            <span><i className="fa fa-check"></i></span>
                          </p>
                        </td>
                        <td className="pricing_01__content__bottom__card">
                          <a href="https://allriseai.com/contact" data-rewardful target="_blank" rel="noreferrer" className="pricing_01__content__bottom__card__button">Contact Us</a>
                        </td>
                      </tr> : ""}
                    {visibleFirst === "show" ?
                      <FeatureRow title="Call Recordings and Transcriptions" firstCheck="check" secondCheck="check" thirdCheck="check" /> : ""}
                    {visibleFirst === "show" ?
                      <FeatureRow title="Pre-Built Workflow Automations" firstCheck="check" secondCheck="check" thirdCheck="check" /> : ""}

                  </table>
                  <table className="pricing_01__content__bottom__table table-2">
                    <tr onClick={() => setVisibleSecond(visibleSecond === "show" ? "hide" : "show")}>
                      <th className="pricing_01__content__bottom__card">
                        <p className="pricing_01__content__bottom__card__title">Additional Features</p>
                      </th>
                      <th className="pricing_01__content__bottom__card">
                      </th>
                      <th className="pricing_01__content__bottom__card"></th>
                      <th className="pricing_01__content__bottom__card"></th>
                      <th className="pricing_01__content__bottom__card"><p className="pricing_01__content__bottom__card__title"><small>(Click to <span>{visibleSecond === "show" ? "hide" : "view"}</span>)</small></p></th>
                    </tr>
                  </table>
                  <table className="pricing_01__content__bottom__table table-2">
                    {visibleSecond === "show" ?
                      <tr>
                        <th></th>
                        <th className="pricing_01__content__bottom__card">
                          <p className="pricing_01__content__bottom__card__title">Tier 1</p>
                        </th>
                        <th className="pricing_01__content__bottom__card">
                          <p className="pricing_01__content__bottom__card__title">Tier 2</p>

                        </th>
                        <th className="pricing_01__content__bottom__card">
                          <p className="pricing_01__content__bottom__card__title">Tier 3</p>
                        </th>
                        <th className="pricing_01__content__bottom__card">
                          <p className="pricing_01__content__bottom__card__title">Custom Solutions</p>
                        </th>
                      </tr>
                      : ""}
                    {visibleSecond === "show" ?
                      <FeatureRow title="Call Streams" first="Inbound Only" second="Inbound Only" third="Inbound and Outbound" /> : ""}
                    {visibleSecond === "show" ?
                      <FeatureRow title="Workflow Changes" firstCheck="times" secondCheck="check" second=" 2 Hours Included" thirdCheck="check" third=" 6 Hours Included" /> : ""}
                    {visibleSecond === "show" ?
                      <FeatureRow title="Calendar Integration" first=" Single Calendar" second=" Multi-Calendar Available" third=" Multi-Calendar Included" /> : ""}

                    {visibleSecond === "show" ?
                      <tr>
                        <td className="pricing_01__content__bottom__card">
                          <p className="pricing_01__content__bottom__card__title">Spam Mitigation</p>
                        </td>
                        <td className="pricing_01__content__bottom__card">
                          <p className="pricing_01__content__bottom__card__title bg-times">
                            <span><i className="fa fa-times"></i></span>
                          </p>
                        </td>
                        <td className="pricing_01__content__bottom__card">
                          <p className="pricing_01__content__bottom__card__title bg-times">
                            <span><i className="fa fa-times"></i></span>
                          </p>
                        </td>
                        <td className="pricing_01__content__bottom__card">
                          <p className="pricing_01__content__bottom__card__title bg-check">
                            <span><i className="fa fa-check"></i> Available</span>
                          </p>
                        </td>
                        <td className="pricing_01__content__bottom__card">
                          <a href="https://allriseai.com/contact" data-rewardful target="_blank" rel="noreferrer" className="pricing_01__content__bottom__card__button">Contact Us</a>
                        </td>
                      </tr> : ""}
                    {visibleSecond === "show" ?
                      <FeatureRow title="Access to AI IVR Team" firstCheck="times" secondCheck="check" second=" Available" thirdCheck="check" third=" Available" /> : ""}
                    {visibleSecond === "show" ?
                      <FeatureRow title="SMS and Email Integration" firstCheck="times" secondCheck="check" second=" Available" thirdCheck="check" third=" Included" /> : ""}
                    {visibleSecond === "show" ?
                      <FeatureRow title="Local Presence" firstCheck="times" secondCheck="times" second="" thirdCheck="check" third=" Available" /> : ""}
                  </table>
                </div>
              </Reveal>

            </div>

          </div>
        </div>

      </div>
    </div>
  );
}

export default Pricing01;
import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "./pages/home";
import Product from "./pages/product";
import Layout from "./components/common/Layout/Layout";
import './fontawesome/css/fontawesome.min.css';
import './fontawesome/css/brands.min.css';
import './fontawesome/css/solid.min.css';
import './fontawesome/css/regular.min.css';
import 'src/css/App.css';
import Faq from "./pages/faq";
import Pricing from "./pages/pricing";
import Contact from "./pages/contact";
import ThankyouP from "./pages/thankyou";
import ReferralPage from "./pages/referral";
import Privacy from "./pages/privacy";
import Disclaimer from "./pages/disclaimer";
import Terms from "./pages/terms";
import RefForm from "./pages/refform";
import RefTerms from "./pages/refterms";
import ReferralContact from "./pages/referralcontact";
import ThankyouC from "./pages/checkoutthankyou";

function App() {

  return (
    <div className="App">
      <Router >
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route path="/" element={<Home />} />
            <Route path="/allriseai" element={<Product />} />
            <Route path="/pricing" element={<Pricing />} />
            <Route path="/faq" element={<Faq />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/contact/referral" element={<ReferralContact />} />
            <Route path="/thankyou" element={<ThankyouP />} />
            <Route path="/thankyoucheckout" element={<ThankyouC />} />
            <Route path="/referral-program" element={<ReferralPage />} />
            <Route path="/referral-signup" element={<RefForm />} />
            <Route path="/referral-terms" element={<RefTerms />} />
            <Route path="privacy" element={<Privacy />} />
            <Route path="disclaimer" element={<Disclaimer />} />
            <Route path="terms" element={<Terms />} />
            <Route path="*" element={<Home />} />
          </Route>
        </Routes>
      </Router>
    </div>
  );
}

export default App;
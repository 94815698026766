export const refPlans = [
  {
    "title": "Tier 1",
    "subTitle": "",
    "baseCost": 495,
    "minutesIncluded": 1500,
    "perMinute": 0.28,
    "carrierCharge": 0,
    "buttonText": "Sign Up",
    "url": "https://checkout.allriseai.com/tier-1"
  },
  {
    "title": "Tier 2",
    "subTitle": "",
    "baseCost": 1995,
    "minutesIncluded": 7000,
    "perMinute": 0.24,
    "carrierCharge": 0,
    "buttonText": "Sign Up",
    "url": "https://checkout.allriseai.com/tier-2"
  },
  {
    "title": "Tier 3",
    "subTitle": "",
    "baseCost": 4995,
    "minutesIncluded": 20000,
    "perMinute": 0.20,
    "carrierCharge": 0,
    "buttonText": "Sign Up",
    "url": "https://checkout.allriseai.com/tier-3"
  },
  {
    "title": "Enterprise",
    "subTitle": "(Level 1)",
    "baseCost": 10000,
    "minutesIncluded": 43500,
    "perMinute": 0.18,
    "carrierCharge": 0,
    "buttonText": "Contact Sales",
    "url": "https://allriseai.com/contact"
  },
  {
    "title": "Enterprise",
    "subTitle": "(Level 2)",
    "baseCost": 25000,
    "minutesIncluded": 119000,
    "perMinute": 0.17,
    "carrierCharge": 0,
    "buttonText": "Contact Sales",
    "url": "https://allriseai.com/contact"
  },
  {
    "title": "Enterprise",
    "subTitle": "(Level 3)",
    "baseCost": 50000,
    "minutesIncluded": 262500,
    "perMinute": 0.15,
    "carrierCharge": 0,
    "buttonText": "Contact Sales",
    "url": "https://allriseai.com/contact"
  },
  {
    "title": "Enterprise",
    "subTitle": "(Level 4)",
    "baseCost": 100000,
    "minutesIncluded": 555500,
    "perMinute": 0.135,
    "carrierCharge": 0,
    "buttonText": "Contact Sales",
    "url": "https://allriseai.com/contact"
  },
  {
    "title": "Enterprise",
    "subTitle": "(Level 5)",
    "baseCost": 250000,
    "minutesIncluded": 1450000,
    "perMinute": 0.12,
    "carrierCharge": 0,
    "buttonText": "Contact Sales",
    "url": "https://allriseai.com/contact"
  }
]